<template>
    <div class="row justify-content-center"> 357 Meadery Inventory </div>
    <div class="row">
      <div class="col">
        <table class="table table-striped table-hover table-sm caption-top">
                    <caption class="caption text-center">Corked 750's Inventory</caption>
                    <thead>  
                  <tr>
                      <th>Location</th>
                      <th>Flavor</th>
                      <th>Container</th>
                      <th>Quantity</th>
                      <th>Price</th>
                  </tr>
                    </thead>
                    <tbody>
                  <tr v-for="item in corkedBottles" v-bind:key="item.index">
                          <td>{{item.ID.substring(0,1)}}</td> 
                          <td>{{item.flavor}}</td>
                          <td>{{item.Container}}</td>
                          <td>{{item.quantity}}</td>
                          <td>${{item.price}}</td>
                    </tr>
                    </tbody>
        </table>
      </div>
      <div class="col">
        <table class="table table-striped table-hover table-sm caption-top">
                    <caption class="caption text-center">Aging Carboys Inventory</caption>
                    <thead>  
                  <tr>
                      <th>Location</th>
                      <th>Flavor</th>
                      <th>Container</th>
                      <th>Quantity</th>
                      <th>Price</th>
                  </tr>
                    </thead>
                    <tbody>
                  <tr v-for="item in agingCarboys" v-bind:key="item.index">
                          <td>{{item.ID.substring(0,1)}}</td> 
                          <td>{{item.flavor}}</td>
                          <td>{{item.Container}}</td>
                          <td>{{item.quantity}}</td>
                          <td>${{item.price}}</td>
                    </tr>
                    </tbody>
        </table>
      </div>
      <div class="col">
        <table class="table table-striped table-hover table-sm caption-top">
                    <caption class="caption text-center">Fermenting Carboys Inventory</caption>
                    <thead>  
                  <tr>
                      <th>Location</th>
                      <th>Flavor</th>
                      <th>Container</th>
                      <th>Quantity</th>
                      <th>Price</th>
                  </tr>
                    </thead>
                    <tbody>
                  <tr v-for="item in fermentingCarboys" v-bind:key="item.index">
                          <td>{{item.ID.substring(0,1)}}</td> 
                          <td>{{item.flavor}}</td>
                          <td>{{item.Container}}</td>
                          <td>{{item.quantity}}</td>
                          <td>${{item.price}}</td>
                    </tr>
                    </tbody>
        </table>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { computed } from "@vue/runtime-core"
import axios from "axios"
// import { response } from "express";
// import HelloWorld from '@/components/HelloWorld.vue'
// import { response } from "express"
export default({
  name : '357 Inventory',
  info: '357 Inventory',
  data(){
    return{
    jsondata: null,
    // filterdata: null
    }
  },
  methods: {
    gd: function(){
    axios.get('https://opensheet.elk.sh/1LRY5tT5dzzqoQIU7dea0giORWo3ixvoKOygbSfDEdHg/to_json')
    .then(response => response.data)
    .then(data=>{
      this.jsondata = data
      // const products = data
      // this.filterdata = products.filter(product => product.includes('currant'))
      // console.log(data)
    })
  }
},
  created(){
    this.gd()
  },
  mounted(){
    this.interval = setInterval(() => {
      this.gd()
    }, 30000);
  }
  ,
  // this.filteredProducts = products.filter(product => product.department.includes('food'))
  computed:{
    corkedBottles: function() {
      return this.jsondata.filter(item => {
        return (item.Container == 'Corked 750ml' && item.flavor.length > 0 && item.quantity > 0) 
        // return item.flavor.length > 0
      })
    },
    agingCarboys: function() {
      return this.jsondata.filter(item => {
        return (item.Container == 'Secondary Carboy5' && item.flavor.length > 0 && item.quantity > 0) 
        // return item.flavor.length > 0
      })
    },
    fermentingCarboys: function() {
      return this.jsondata.filter(item => {
        return (item.Container == 'Primary Carboy6' && item.flavor.length > 0 && item.quantity > 0) 
        // return item.flavor.length > 0
      })
    }
    //
  }
})
</script>
